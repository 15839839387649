const InvoiceIndex = () => import('@/modules/invoices/views/InvoiceIndex.vue')

export default [
  {
    path: '/invoices',
    name: 'invoices.index',
    component: InvoiceIndex,
    meta: { requiresAuthentication: true }
  }
]
