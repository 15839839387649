import axios from '@/plugins/axios'
import store from '@/store'

/**
 * Login user
 *
 * @param {*} variables
 * @returns
 */
const login = async function ({ email, password }) {
  try {
    const user = {
      use_2fa: false,
      email: null,
      is_email_verified: false
    }

    const response = await axios.post('admin/login', {
      email: email,
      password: password
    })
    const { data } = response

    user.is_email_verified = data.user.is_email_verified
    user.email = data.user.email

    // If use 2fa in login, dont save access_token
    if (typeof data === 'object' && data.user.use_2fa) {
      user.use_2fa = true
      return user
    }

    // If has access token, save it on session
    if (data.access_token) onLogin(data.access_token)
    return user
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Logout
 *
 * @returns
 */
const logout = async () => {
  try {
    await axios.post('v1/logout')
    onLogout()
    return true
  } catch (error) {
    return Promise.reject(error)
  }
}

const onLogout = () => {
  localStorage.removeItem('access_token')
  store.dispatch('auth/setAuthenticated', { authenticated: false }, { root: true })
}

const onLogin = function (token) {
  localStorage.setItem('access_token', token)
  store.dispatch('auth/setAuthenticated', { authenticated: true }, { root: true })
}

/**
 * Validate 2FA code on backend
 *
 * @param {*} param0
 * @returns
 */
const validate2FACode = async ({ code, email }) => {
  try {
    const params = {
      code,
      email
    }
    const response = await axios.post('v1/two-factor-authentication/authenticate', params)
    const { data } = response
    onLogin(data.access_token)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Send 2FA code again to email
 *
 * @param {*} param0
 * @returns
 */
const resend2FACode = async ({ email }) => {
  try {
    const params = {
      email: email
    }
    const response = await axios.post('v1/two-factor-authentication/request', params)
    const { data } = response

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  login,
  onLogin,
  onLogout,
  logout,
  resend2FACode,
  validate2FACode
}
