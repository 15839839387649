import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthStore from '@/modules/auth/store'
import store from '@/store'

import advertisersRoutes from '@/modules/advertisers/router'
import campaignsRoutes from '@/modules/campaigns/router'
import companiesRoutes from '@/modules/companies/router'
import couponsRoutes from '@/modules/coupons/router'
import creativesRoutes from '@/modules/creatives/router'
import invoicesRoutes from '@/modules/invoices/router'
import ordersRoutes from '@/modules/orders/router'
import orderClaimsRoutes from '@/modules/order-claims/router'
import insertionOrdersRoutes from '@/modules/insertion-orders/router'
import pixelsRoutes from '@/modules/pixels/router'
import reportsRoutes from '@/modules/reports/router'
import usersRoutes from '@/modules/users/router'

Vue.use(VueRouter)

const Home = () => {
  return AuthStore.state.authenticated
    ? import('@/modules/home/views/Home.vue')
    : import('@/modules/auth/views/Login.vue')
}

const NotFound = () => import('@/modules/404/views/404.vue')

const routes = [
  {
    path: '/',
    component: Home,
    name: 'home'
  },
  ...advertisersRoutes,
  ...campaignsRoutes,
  ...companiesRoutes,
  ...couponsRoutes,
  ...creativesRoutes,
  ...invoicesRoutes,
  ...ordersRoutes,
  ...orderClaimsRoutes,
  ...insertionOrdersRoutes,
  ...pixelsRoutes,
  ...reportsRoutes,
  ...usersRoutes,
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('clearErrors')
  if (to.matched.some(route => route.meta.requiresNotAuthenticated)) {
    if (AuthStore.state.authenticated) {
      return next({ path: '/' })
    }
  }
  if (to.matched.some(route => route.meta.requiresAuthentication)) {
    if (!AuthStore.state.authenticated) {
      return next({ path: '/' })
    }
  }
  next()
})

export default router
