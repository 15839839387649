// import router from '@/router'
import axios from '@/plugins/axios'

/**
 * Get zicpode data
 * @param {*} zipcode
 */
const getZipcodeData = async zipcode => {
  if (zipcode.length <= 0) return false
  try {
    const response = await axios.get('addresses/zipcode', {
      params: {
        zipcode: zipcode
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get the error response and create the error array
 */
const parseErrorResponseToArray = error => {
  let data
  if (error.response && error.response.data && error.response.data.errors) {
    data = error.response.data.errors
  } else if (error.errors) {
    data = error.errors
  } else {
    data = ['Houve um problema ao processar a requisição, tente novamente mais tarde']
  }
  return data
}

/**
 * Register vuex module
 *
 */
const registerVuexModule = (rootStore, moduleName, store) => {
  if (!(moduleName in rootStore._modules.root._children)) {
    rootStore.registerModule(moduleName, store)
  }
}

export {
  getZipcodeData,
  parseErrorResponseToArray,
  registerVuexModule
}
