const CampaignListShow = () => import('@/modules/reports/views/CampaignListShow.vue')
const FinancialShow = () => import('@/modules/reports/views/FinancialShow.vue')
const RevenueShow = () => import('@/modules/reports/views/RevenueShow.vue')
const SpendingShow = () => import('@/modules/reports/views/SpendingShow.vue')
const TestStatusShow = () => import('@/modules/reports/views/TestStatusShow.vue')
const UsersShow = () => import('@/modules/reports/views/UsersShow.vue')
const CommissionPayableShow = () => import('@/modules/reports/views/CommissionPayableShow.vue')

export default [
  {
    path: '/reports/campaign-list',
    name: 'reports.campaign-list.show',
    component: CampaignListShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/reports/financial',
    name: 'reports.financial.show',
    component: FinancialShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/reports/revenue',
    name: 'reports.revenue.show',
    component: RevenueShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/reports/spending',
    name: 'reports.spending.show',
    component: SpendingShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/reports/test-status',
    name: 'reports.test-status.show',
    component: TestStatusShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/reports/users',
    name: 'reports.users.show',
    component: UsersShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/reports/commission-payable',
    name: 'reports.commission.payable',
    component: CommissionPayableShow,
    meta: { requiresAuthentication: true }
  }
]
