import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/filters'

import '@/plugins/bootstrap-vue'
import '@/plugins/vuelidate'
import '@/plugins/vue-meta'
import '@/plugins/v-mask'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
