const OrderIndex = () => import('@/modules/orders/views/OrderIndex.vue')

export default [
  {
    path: '/orders',
    name: 'orders.index',
    component: OrderIndex,
    meta: { requiresAuthentication: true }
  }
]
