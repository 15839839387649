const AdvertiserIndex = () => import('@/modules/advertisers/views/AdvertiserIndex.vue')
const AdvertiserShow = () => import('@/modules/advertisers/views/AdvertiserShow.vue')

export default [
  {
    path: '/advertisers',
    name: 'advertisers.index',
    component: AdvertiserIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/advertisers/:id',
    name: 'advertisers.show',
    component: AdvertiserShow,
    meta: { requiresAuthentication: true }
  }
]
