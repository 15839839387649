const CampaignIndex = () => import('@/modules/campaigns/views/CampaignIndex.vue')
const CampaignShow = () => import('@/modules/campaigns/views/CampaignShow.vue')

export default [
  {
    path: '/campaigns',
    name: 'campaigns.index',
    component: CampaignIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/campaigns/:id',
    name: 'campaigns.show',
    component: CampaignShow,
    meta: { requiresAuthentication: true }
  }
]
