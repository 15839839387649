const OrderClaimIndex = () => import('@/modules/order-claims/views/OrderClaimIndex.vue')
const ClaimSalesIndex = () => import('@/modules/order-claims/views/ClaimSalesIndex.vue')
const MySalesIndex = () => import('@/modules/order-claims/views/MySalesIndex.vue')

export default [
  {
    path: '/order-claims',
    name: 'order.claims.index',
    component: OrderClaimIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/claim-sales',
    name: 'claim.sales.index',
    component: ClaimSalesIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/my-sales',
    name: 'my.sales.index',
    component: MySalesIndex,
    meta: { requiresAuthentication: true }
  }
]
