const CompanyIndex = () => import('@/modules/companies/views/CompanyIndex.vue')
const CompanyShow = () => import('@/modules/companies/views/CompanyShow.vue')

export default [
  {
    path: '/companies',
    name: 'companies.index',
    component: CompanyIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/companies/:id',
    name: 'companies.show',
    component: CompanyShow,
    meta: { requiresAuthentication: true }
  }
]
