const UserIndex = () => import('@/modules/users/views/UserIndex.vue')
const UserShow = () => import('@/modules/users/views/UserShow.vue')

export default [
  {
    path: '/users',
    name: 'users.index',
    component: UserIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/users/:id',
    name: 'users.show',
    component: UserShow,
    meta: { requiresAuthentication: true }
  }
]
