import * as types from './mutation-types'

export default {
  clearErrors ({ commit }) {
    commit(types.SET_ERRORS, { errors: null })
  },
  clearNotification ({ commit }) {
    commit(types.SET_NOTIFICATION, { notification: null })
  },
  setErrors ({ commit }, payload) {
    commit(types.SET_ERRORS, payload)
  },
  setNotification ({ commit }, payload) {
    commit(types.SET_NOTIFICATION, payload)
  }
}
