const InsertionOrderIndex = () => import('@/modules/insertion-orders/views/InsertionOrderIndex.vue')
const InsertionOrderShow = () => import('@/modules/insertion-orders/views/InsertionOrderShow.vue')
const InsertionOrderEdit = () => import('@/modules/insertion-orders/views/InsertionOrderEdit.vue')

export default [
  {
    path: '/insertion-orders',
    name: 'insertion.orders.index',
    component: InsertionOrderIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/insertion-orders/create',
    name: 'insertion.orders.create',
    component: InsertionOrderEdit,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/insertion-orders/:id',
    name: 'insertion.orders.show',
    component: InsertionOrderShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/insertion-orders/:id/edit',
    name: 'insertion.orders.edit',
    component: InsertionOrderEdit,
    meta: { requiresAuthentication: true }
  }
]
