<template>
  <div id="kt_body">
    <router-view/>
  </div>
</template>

<script>
import AuthStore from '@/modules/auth/store'
import UsersStore from '@/modules/users/store'
import UsersService from '@/modules/users/services/users-service'
import { mapState, mapActions } from 'vuex'
import { registerVuexModule } from '@/utils'
import seo from '@/mixins/seo'

export default {
  name: 'App',
  mixins: [seo],
  components: {
  },
  metaInfo () {
    return {
      title: this.seoDefaultTitle,
      titleTemplate: `%s | ${this.seoDefaultTitleAppend}`,
      meta: [
        { vmid: 'description', name: 'description', content: this.seoDefaultDescription },
        { vmid: 'og-description', name: 'og:description', content: this.seoDefaultDescription },
        { vmid: 'og-title', name: 'og:title', content: this.seoDefaultTitle },
        { vmid: 'og-url', name: 'og:url', content: this.currentUrl },
        { vmid: 'og-image', name: 'og:image', content: this.seoDefaultImage },
        { vmid: 'og-type', name: 'og:type', content: 'website' }
      ]
    }
  },
  created () {
    registerVuexModule(this.$store, 'auth', AuthStore)
    registerVuexModule(this.$store, 'users', UsersStore)

    // Get the logged user when the page first loads
    this.getLoggedUser()
  },
  computed: {
    ...mapState('auth', {
      authenticated: state => state.authenticated
    }),
    ...mapState('users', {
      user: state => state.user
    }),
    ...mapState({
      errors: state => state.errors,
      isSidebarOpen: state => state.isSidebarOpen,
      notification: state => state.notification
    }),
    isUnloggedPage () {
      const route = this.$route.name
      if (
        (route === 'home' && !this.authenticated)
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions(['clearErrors']),
    clearGlobalErrors () {
      this.clearErrors()
    },
    /**
     * Get logged user
     */
    async getLoggedUser () {
      // Check if the user is authenticated and if the user in the store is filled
      if (this.authenticated) {
        if (!this.user) {
          try {
            await UsersService.me()
          } catch (error) {
            error.handleGlobally && error.handleGlobally()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
</style>
