const CouponIndex = () => import('@/modules/coupons/views/CouponIndex.vue')
const CouponShow = () => import('@/modules/coupons/views/CouponShow.vue')
const CouponEdit = () => import('@/modules/coupons/views/CouponEdit.vue')

export default [
  {
    path: '/coupons',
    name: 'coupons.index',
    component: CouponIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/coupons/create',
    name: 'coupons.create',
    component: CouponEdit,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/coupons/:id',
    name: 'coupons.show',
    component: CouponShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/coupons/:id/edit',
    name: 'coupons.edit',
    component: CouponEdit,
    meta: { requiresAuthentication: true }
  }
]
