import axios from 'axios'
import store from '@/store'
// import router from '@/router'
import AuthStore from '@/modules/auth/store'
import AuthService from '@/modules/auth/services/auth-service'
import { parseErrorResponseToArray } from '@/utils'

const instance = axios.create({})

// Handle global errors
const errorComposer = (error) => {
  return () => {
    const array = parseErrorResponseToArray(error)
    const data = { errors: array }
    store.dispatch('setErrors', data)
  }
}

instance.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token')
  if (token && AuthStore.state.authenticated) {
    config.headers.common.Authorization = `Bearer ${token}`
  }
  store.dispatch('clearErrors')

  return config
}, (error) => {
  error.handleGlobally = errorComposer(error)
  return Promise.reject(error)
})

instance.interceptors.response.use(response => {
  return response
}, error => {
  // Treat if is unauthorized response and logout user (401)
  if (error.response && error.response.status === 401) {
    AuthService.onLogout()
    return location.reload()
  } else {
    // if is 404, redirect to home
    if (error.response) {
      if (error.response.status === 404) {
        // router.push('/')
      }
    }
    error.handleGlobally = errorComposer(error)
  }
  return Promise.reject(error)
})

export default instance
