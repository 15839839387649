const CreativeIndex = () => import('@/modules/creatives/views/CreativeIndex.vue')
const CreativeShow = () => import('@/modules/creatives/views/CreativeShow.vue')

export default [
  {
    path: '/creatives',
    name: 'creatives.index',
    component: CreativeIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/creatives/:id',
    name: 'creatives.show',
    component: CreativeShow,
    meta: { requiresAuthentication: true }
  }
]
