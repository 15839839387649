import {
  SET_ERRORS,
  SET_NOTIFICATION
} from './mutation-types'

export default {
  [SET_ERRORS]: (state, { errors }) => {
    state.errors = errors
  },
  [SET_NOTIFICATION]: (state, { notification }) => {
    state.notification = notification
  }
}
