const PendingCreation = () => import('@/modules/pixels/views/PendingCreation.vue')

export default [
  {
    path: '/pixels/pending-creation',
    name: 'pixels.pending.index',
    component: PendingCreation,
    meta: { requiresAuthentication: true }
  }
]
